/* Slogan und PageName im Header */
#global-page-header .page-info {
	margin-top: 50px;
	.black-row;
	@media screen and(min-width: @screen-md) {
		margin-top: 91px;
	}
	.page-info-row {
		margin: 0;
		#page-name {
			color: @orange;
			font-size: xx-large;
			font-weight: 800;
			line-height: 27px;
		}
		
		#page-slogan {
			font-size: x-large;
			padding-left: 15px;
			line-height: 0;
			.slogan-font;
		}
		#page-icon {
			font-weight: 800;
			font-size: 38px;
			background: @orange;
			color: @darkblue;
			padding: 1px;
			.borderradius(5px);
			height: 56px;
			width: 54px;
		}
		.page-infobox {
			padding-left: 5px;
			padding-right: 0;
			line-height: 20px;
			@media screen and (min-width: @screen-md) {
				display: inline-block;
				width: auto;
			}
		}
	}
}
