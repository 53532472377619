.contact-inner {
	& > section {
		font-size: 0;
		& > span {
			display: inline-block;
			font-size: 25px;
			background-color: @orange;
			text-align: center;
			border-radius: 22px;
			width: 44px;
			height: 44px;
			color: white;
			vertical-align: middle;
			&.fa-phone {padding: 11px;}
			&.fa-envelope {padding: 9px;}
		}
		& > div {
			display: inline-block;
			font-size: 20px;
			vertical-align: middle;
			.slogan-font;
			padding-left: 10px;
			margin-top: 8px;
		}
	}
}
#global-page-header .page-contact {
	.fa {color: @darkblue;}		//Icon
	margin-top: 30px;
	padding: 0 15px 0;
	.black-row;
	.contact-inner;
	& > section.sec-email {
		position: absolute;
		right: 16px;
		top: 4px;
	}
}

.page-contact-desktop {
	display: none;
	.fa {color: @darkblue;}		//Icon
	@media screen and (min-width: @screen-md) {
		width: 600px;
		display: inline-block;
		float: right;
		.contact-inner;
		padding: 6px 0 6px 0;
		section {
			display: inline-block;
			padding-left: 3%;
			position: static;
			width: 250px;
		}
	}
}
