.field-artikelbild {
	text-align: center;
}
.field-artikelbild-display {
	.boxshadow(0 0 3px @black);
	display:    inline-block;
	float:      none;
	margin: 20px auto;
    border: @orange solid 3px;
	.borderradius(4px);
	overflow:   hidden;
	@media screen and(max-width: @screen-xs) {
		text-align:    center;
		margin-bottom: 18px;
	}
}
