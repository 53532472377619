.sidebar-menu-container h3 {
	margin-top: 10px;
}

.stacked-menu {
	.caret-container {
		float: right;
	}

	.nav-container {
		text-transform: uppercase;
		font-weight: bold;
	}

	.nav-sub-container {
		font-weight: bold;
		cursor: pointer;
	}

	ul.nav-stacked ul.nav-stacked > li > a {
		padding-left: 30px;
	}

	border: solid @orange 4px;

	//Custom
	& > li {
		a {
			padding: 5px 10px;
		}
		margin-top: 0 !important;
		background: @darkblue;
		& > a {
			border-bottom: solid 1px #19242F;
			border-top: solid 1px #3A4958;
			color: @orange;
			&:hover, &:active {
				background-color: @darkdarkblue;
			}
		}
		& > ul {
			border-bottom: solid 1px #19242F;
			& > li {
				& > a {
					color: @white;
					font-weight: normal !important;
					padding-left: 15px;
					&:hover, &:active {
						color: @orange;
						background-color: @darkdarkblue;
					}
				}
			}
			& > ul {
				& > li {
					& > a {
						color: @white;
						&:hover, &:active {
							color: @orange;
							background-color: @darkdarkblue;
						}
					}
				}
			}
		}
	}
	& > li.no-deeper.active > a {
		background-color: @darkdarkblue;
	}
	& > li.active {
		background: @darkblue;
		& > a {
			border-bottom: solid 1px #19242F;
			color: @orange;
			&:hover, &:active {
				background-color: @darkdarkblue;
			}
		}
		& > ul {
			border-top: #3A4958 solid 1px;
			& > li > a {
				color: @white;
				&:hover, &:active {
					color: @orange;
					background-color: @darkdarkblue;
				}
			}
			& > li.active a {
				color: @orange;
				background-color: @darkdarkblue;
				&:hover, &:active {
					color: @orange;
					background-color: @darkdarkblue;
				}
			}
			& > ul {
				& > li {
					& > a {
						color: @white;
						&:hover, &:active {
							color: @orange;
							background-color: @darkdarkblue;
						}
					}
				}
			}
		}
	}
}
