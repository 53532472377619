#footer {
	padding: 10px;
	& > .nav-pills {
		.noise-2;
		ul.navbar-nav {
			padding: 5px 0;
			margin: 0 auto;
			display: block;
			float: none;
			& > li {
				float: none;
				display: block;
				&.active > a {
					background-color: @darkdarkblue;
					color: @orange;
					&:hover {
						background-color: @darkdarkblue;
					}
				}
				& > a {
					padding: 5px;
					margin: 0px 5px;
					.borderradius(4px);
					color: @white;
					font-weight: 600;
					&:hover {
						background: none;
						color: @orange;
					}
				}
			}
		}
	}
	@media screen and (min-width: 500px) {
		& > .nav-pills {
			padding: 0;
			ul.navbar-nav {
			   & > li {
					display: inline-block;
					& > a {
						.borderradius(0);
					}
				}
			}
		}
	}
}
