#flexicontent.fctypearticle, #flexicontent.fctypelinklist, #flexicontent.fctypevideo {
	.field_einleitung {
		.less_einleitung;
		& > ul {
			.less_einleitung_ul;
		}
	}
	.field_text {
		h4 {
			margin-top: 1.2em;
		}
		ul {
			margin-left: 20px;
		}
		text-align: justify;
	}
	.singlearrow {
		.singlearrow();

		a:hover {
			text-decoration: underline;
		}
		a {color: @black;}
	}
	.singlearrow-hover {
		.singlearrow-hover();

		a:hover {
			text-decoration: underline;
		}
		a {color: @black;}
	}
	.doublearrow {
		.doublearrow();

		a:hover {
			text-decoration: underline;
		}
		a {color: @black;}
	}
	.doublearrow-hover {
		.doublearrow-hover();

		a:hover {
			text-decoration: underline;
		}
		a {color: @black;}
	}
}

