@import '_vars';

.posts-component {
	text-align: justify;
	.featured-images p {
		.featured-image_;
	}
}

.post-list {
	margin-top: 30px;

	.layout-image, .layout-grid {
		margin-bottom: 20px;
		.grid-image {
			border:              @border-outer;
			border-radius:       4px;
			padding:             0;
			box-shadow:          @shadow-outer;
			display:             block;
			background-size:     contain;
			background-position: 50% 52px;
			background-repeat: repeat-x;
			box-sizing: border-box;

			//Gleiche Höhe, 16:9
			.grid-image-bg {
				height: 267px;
				.grid-image-scale {
					height: 267px;
				}
			}
			@media screen and (max-width: @screen-lg) {
				.grid-image-bg {
					height: 323px;
					.grid-image-scale {
						height: 323px;
					}
				}
			}	
			@media screen and (max-width: @screen-md) {
				.grid-image-bg {
					height: 255px;
					.grid-image-scale {
						height: 255px;
					}
				}
			}	
			@media screen and (max-width: @screen-sm) {
				.grid-image-bg {
					height: 76.5vw;
					.grid-image-scale {
						height: 76.5vw;
					}
				}
			}	

			&:hover {
				text-decoration: none;
				.grid-image-bg {
					background-color: rgba(0, 0, 0, 0.8);
					.grid-image-scale {
						transform: scale(1, 1);
					}
				}
			}
			.grid-image-caption {
				display:       block;
				width:         100%;
				padding:       12px 0px 0px 0px;
				background:    @caption-bg;
				cursor:        pointer;
				border-radius: 3px 3px 0 0;
				border-bottom: @border-inner;
				height: 52px;
				& > h1, & > h2, & > h3 {
					margin:      0;
					padding:     0;
					a {
						color:       @fontcolor;
						font-family: @caption-font; 
						font-size:   25px;
						font-weight: bold;
						text-align:  center;
						display: block;
					}
				}
			}
			.grid-image-bg {
				display:          block;
				background-color: rgba(0, 0, 0, 0);
				transition:       background-color 0.5s;
				width:            100%;
				padding: 10px;
				.grid-image-scale {
					display:    block;
					transition: transform 0.5s;
					transform:  scale(0, 0);
					.grid-image-readmore {
						position:      absolute;
						bottom:        9%;
						width:         70%;
						margin-left:   16%;
						display:       block;
						padding:       4px;
						background:    linear-gradient(to bottom, @readmore-gradient-1 0%, @readmore-gradient-2 100%);
						font-family:   @readmore-font;
						color:         @fontcolor;
						font-weight:   bold;
						font-size:     22px;
						/* line-height: 42px; */
						opacity:       0.9;
						cursor:        pointer;
						box-shadow:    0 0 1px @fontcolor;
						border-radius: 3px;
						text-align:    center;
						&:hover {
							color:           @fontcolor;
							text-decoration: underline;
						}
						&:focus {
							outline: none;
							color:   @fontcolor;
						}
					}
					.grid-image-desc {
						color:       @fontcolor;
						font-family: @desc-font;
						font-size:   20px;
						display:     block;
						padding:     5px;
						text-align:  center;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
					}
				}
			}
		}
	}
}

/* Grid */








