/* Footer global styling + Stickyfooter CSS */

#footer {
	border-top: 4px @orange solid;
	display: block;
	width: 100%;
	padding: 0;
	background-color: @darkblue;
	font-size: 14px;
    z-index: 100000;
    position: relative;
}

/* Sticky Footer by Ryan Fait | http://ryanfait.com/ */
* {
	margin: 0;
}
html, body {
	height: 100%;
}
.wrapper {
	min-height: 100%;
	height: auto !important; /* This line and the next line are not necessary unless you need IE6 support */
	height: 100%;
	margin: 0 auto -154px; /* the bottom margin is the negative value of the footer's height */
	@media screen and (min-width: 500px) {
		margin: 0 auto -64px; /* the bottom margin is the negative value of the footer's height */
	}
}
.footer, .push {
	height: auto;
}

