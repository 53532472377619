div.pagination {
	.center-block;
	text-align: center;
	ul {
		.pagination;
		display: inline-block;
		margin: auto;
		border: 1px @orange solid;
		li {
			display: inline-block;
			span {
				display: block;
				float: none;
				border: 0 !important;
				background: @darkblue;
				color: @grey;
				z-index: auto !important;
			}
			a {
				display: block;
				float: none;
				border: 0 !important;
				background: @darkblue;
				color: @orange;
				z-index: auto !important;
				&:hover {
					text-decoration: underline;
				}
			}
			a:hover, a:focus {
				z-index: 2;
				color: @orange;
				background-color: @darkblue;
			}
			span:hover, span:focus {
				z-index: 2;
				color: @grey;
				background-color: @darkblue;
			}

			&:first-child > a, &:first-child > span {
				border-bottom-left-radius: 2px;
				border-top-left-radius: 2px;
			}
			&:last-child > a, &:last-child > span {
				border-bottom-right-radius: 2px;
				border-top-right-radius: 2px;
			}
		}
	}
}
