@import "partials/navbar-right";
@import "partials/has-share-button";

//Wrapper for vue scrolling
.v-own-navbar-wrapper {
	top: 0;
	width: 100%;
	z-index: 999;
	font-size: 14px;
}
#navbar {
	width:        100%;
	z-index:      1000;
	padding-left: 0;
	top:          0;
	border:       0px;
	.noise-2 !important;
	@media screen and (min-width: @screen-sm) {
		border-top:    solid 4px @orange;
		border-bottom: solid 4px @orange;
		margin-bottom: 0;
	}
	
	.navbar-brand span {
		padding:     0 6px;
		background:  @orange;
		color:       @darkblue;
		font-weight: 800;
		.borderradius(3px);
	}
}

.navbar-a-hover {
	color:           @orange !important;
	text-decoration: none;
}
.navbar-a {
	color:           white !important;
	text-decoration: none;
	font-weight:     600;
}
.li-hover {
	background-color: @darkdarkblue;
}

.menu-navbar-bar {
	background-color: #243445;
	border-bottom:    solid 4px @orange;
	margin-bottom:    0;
	padding: 10px 5px;
	.noise-2;
	.navbar-brand-icon {
		background-color: @orange;
		color: @darkblue;
	}
	& > ul {
		& > li {
			& > a {
				.navbar-a;
			}
			& > ul {
				background-color: #243445;
				border: #d18e3c solid 2px;
				& > li {
					.noise-2;
					& > a {
						.navbar-a;
					}
				}
				& > li:hover {
					.li-hover;
					.noise-2;
					& > a {
						.navbar-a-hover;
					}
				}
			}
		}
		& > li:hover {
			.li-hover;
			.noise-2;
			& > a {
				.navbar-a-hover;
			}
		}
	}
	a, a:focus, a:active, a:hover, a:visited {
		.navbar-a;
	}
	& > ul.navbar-own-right {
		.navbar-right();
		.has-share-button();
	}
}

//Mobile navbar for smartphones
.own-navbar-mobile {
	.noise-2;
	background-color: @darkblue;
}
.own-menu-mobile {
	background: #243445;
	z-index: 1000000;
	.own-menu-mobile-close .fa {	//White closing icon in top right corner
		color: white;
	}
	.menu-navbar-list {
		padding-left: 0;
		min-height: 100%;
		height: 100%;
		position: absolute;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		list-style-type: none;
		& > li {
			margin: 10px auto;
			& > a {
				.navbar-a;
				&:hover {
					.navbar-a-hover;
				}
			}
			& > ul {
				//Hide second submenus - navigate through content
				display: none;
			}
		}
	}
}

.navMainContainer.navFixed {
    position: relative;
    top: 0;
    position: fixed;
	z-index: 10001;
}
