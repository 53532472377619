.menu-navbar-bar {
	width:   100%;
	z-index: 1000;
	padding: 0 0 0 15px;
	margin:  0;
	display: none;			//Hide navbar in mobile views and display .menu-navbar-mobile-bar instead
	@media screen and (min-width: @screen-sm) {
		display: block;
	}
	@media screen and (max-width: 1040px) {
		padding: 0 5px;
	}
	.navbar-brand-icon {
		width: 45px;
		height: 45px;
		float: left;
		font-weight: 800;
		border-radius: 3px;
		box-shadow: 3px;
		padding-top: 5px;
		line-height: 33px;
		text-align: center;
		font-size: 36px;
		margin-top: 4px;
		margin-left: 6px;
		@media screen and (max-width: 1040px) {
			display: none;
		}
	}
	& > ul {
		list-style-type: none;
		margin:          0;
		padding:         0;
		& > li {
			margin:   0 2px;
			display:  inline-block;
			position: relative;
			z-index:10004;
			& > a, & > span {
				display: block;
				padding: 15px;
				@media screen and(max-width: 1155px) {
					padding: 15px 5px;
				}
			}
			& > ul {
				list-style-type: none;
				display: none;
				top: 60px;
				left: 50%;
				padding-left: 0;
				width: 300px;
				position: absolute;
				top: 75px;
				margin-left: -150px;
				.arrow-top(@darkblue, @orange);
				& > li > a {
					padding: 10px 10px;
					display: block;
				}
			}
		}
	}
}

.own-menu-mobile {
	display: none;	
	position: fixed;
	width: 100%;
	z-index: 9999;
		min-height: 100%;	
	top: 0;
	& > ul > li > a {
		display: block;
		padding: 10px 0;
		text-align: center;
	}
	.own-menu-mobile-close {
		position: absolute;
		top: 1%;
		right: 1%;
		z-index: 999999;
		width: 40px;
		height: 40px;
		display: block;
		.fa {
			color:black; 
			font-size: 35px;
			cursor: pointer;
		}	
	}
}

//mobile bar on top
.own-navbar-mobile {
	display: block;
	background: grey;
	@media screen and (min-width: @screen-sm) {
		display: none;
	}
	& > .row {
		margin: 0;
		& > button {
			padding: 0;
			margin: 8px 15px 8px 0;
			border: 0;
			background: transparent;
			& > .fa {
				font-size: 26px;
				color: @white;
			}
		}
	}
}
