.navbar-right() {
    position: absolute;
    right: 0;
    margin-right: 20px;
    line-height: 50px;
	top: 0;
	@media screen and (max-width: 850px) {
		display: none;
	}
}
