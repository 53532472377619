.slogan-font {
	font-family: 'Calligraffitti', cursive;
	color: white;
	font-weight: bold;
}

.black-row {
	background-color: rgba(0,0,0,0.5); 
	padding: 0 15px 0;
	padding-top: 4px;
	padding-bottom: 4px;
}

header#global-page-header {
	overflow: hidden;
	
	/* This is initial setup - it will be overwritten by vue header-scroller script * (see /js/scroll.js) */
	height: 260px;
	top: 0;

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
	
	width: 100%;
	display: none;
	z-index: 999;
	@media screen and (min-width: @screen-sm) {
		display: block;
	}
	.custom-contact {
		position: absolute;
		width: 100%;
	}
}

.global-page-header-fixed {
    overflow: hidden;
    height: 160px;
    position: fixed;
    margin-top: 74px;
	z-index: -1;
}
