.fccategory {
	.category-info {
		.catimg {
			.less_artikelbild;
			float: left;
		}
		.catdescription {
			.less_einleitung;
			color: #888;
			font-style: italic;
			& > ul {
				.less_einleitung_ul;
			}
		}
	}
	.categorylist-item {
		padding-top: 10px;
		h2.item-title {
			margin-bottom: 0;
			font-size: x-large;
			margin-bottom: 10px;
		}
		.field_display.field_artikelbild {
			.less_artikelbild;
			float: left;
		}
		.item-right {
			.field-display {
				.less_einleitung;
				ul {
					.less_einleitung_ul;
				}
			}
		}
		.item-bottom {
			h3 {
				font-size: medium;
				margin-top: 0px;
			}
		}
	}

	.singlearrow {
		.singlearrow();

		a:hover {
			text-decoration: underline;
		}
		a {color: @black;}
	}
	.singlearrow-hover {
		.singlearrow-hover();

		a:hover {
			text-decoration: underline;
		}
		a {color: @black;}
	}
	.doublearrow {
		.doublearrow();

		a:hover {
			text-decoration: underline;
		}
		a {color: @black;}
	}
	.doublearrow-hover {
		.doublearrow-hover();

		a:hover {
			text-decoration: underline;
		}
		a {color: @black;}
	}
	
	//Hide no items message and return message in category views
	.noitems, .fc_return_msg {
		display: none;
	}
}
