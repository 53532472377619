.important-link {
	& > a {
		color: @orange !important;
	}
	background-color: @darkdarkblue;
}

#navbar {
	background-color: @darkblue;
}

#main-menu {
	.sub-arrow {
		@media screen and (min-width: @screen-md) {
			display: none;
		}
	}
	& > li {
		&:hover {
			@media screen and (min-width: @screen-md) {
				.important-link;
				.noise-2 !important;
			}
		}
		margin: 0 4px;
		a, a:focus, a:hover, a:visited {
			color: white;
			background: none;
		}
		& > a {
			font-weight: 600;
		}
		&.active > a {
			color: @orange;
			background: @darkdarkblue;
			.noise-2 !important;
		}
		& > ul {
			& > li {
				.noise-2 !important;
				&:hover {
					@media screen and (min-width: @screen-md) {
						.important-link;
					}
				}
				&.active {
					.important-link;
				}
				& > a {
					font-weight: 400;
					 @media screen and (min-width: @screen-md) {
						 padding-top: 13px;
						 padding-bottom: 13px;
					 }
				}
				& > ul {
					@media screen and (min-width: @screen-md) {
						position: absolute;
						background: @darkblue;
						border: @orange 2px solid;
					}
					& > li {
						&:hover {
							@media screen and (min-width: @screen-md) {
								.important-link;
							}
						}
						&.active > a {
							color: @orange;
							background: @darkdarkblue;
						}
						& > a {
							font-weight: 400;
							@media screen and (min-width: @screen-md) {
								padding-top: 13px;
								padding-bottom: 13px;
							}
						}
					}
				}
			}
			@media screen and (min-width: @screen-md) {
				position: absolute;
				top: 50px !important;
				background: @darkblue;
				border: @orange 2px solid;
				&:after, &:before {
					bottom: 100%;
					left: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}

				&:after {
					border-color: rgba(36, 52, 69, 0);
					border-bottom-color: #243445;
					border-width: 10px;
					margin-left: -10px;
				}
				&:before {
					border-color: rgba(209, 142, 60, 0);
					border-bottom-color: #D18E3C;
					border-width: 13px;
					margin-left: -13px;
				}
			}
		}
	}
}
