//Featured image
.post-component {
	.featured-images p {
		.featured-image_;
	}

	.post-description, .post-excerpt {
		text-align: justify;
	}

	.quelle-wrapper, .weiteres-wrapper {
		.quelle-header, .weiteres-header {
			margin-top: 40px;
		} 
		a {
			.singlearrow-hover();
			display: block;
		}
	}
}
