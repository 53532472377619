.featured-image_ {
    box-shadow: 0 0 3px #000000;
    display: inline-block;
    float: none;
    margin: 20px auto;
    border: #D18E3C solid 3px;
    -moz-border-radius: 4px;
    -webkit-box-shadow: 4px;
    border-radius: 4px;
    overflow: hidden;
}
