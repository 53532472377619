#navTopContainer {
	border-bottom: solid 1px #3A4958;
	background: @darkblue;
	font-size: 14px;

	& > .nav-pills {
		.noise-2;
		ul.navbar-nav {
			padding: 5px 0;
			margin: 0 !important;
			& > li {
				float: none;
				display: inline-block;
				&.active > a {
					background-color: @darkdarkblue;
					color: @orange;
					&:hover {
						background-color: @darkdarkblue;
					}
				}
				& > a {
					padding: 5px;
					margin: 0px 5px;
					.borderradius(4px);
					color: @white;
					font-weight: 600;
					&:hover {
						background: none;
						color: @orange;
					}
				}
			}
		}
	}
	&:after  {
		border-top: #19242F solid 1px;
		display: block;
		height: 0px;
		content: "";
	}
	@media screen and (min-width: @screen-sm) {
		border-bottom: solid 4px @orange;
		&:after {
			border-top: 0;
		}
		& > .nav-pills {
			& > .navbar-nav {
				float: right;
			}
			padding: 0;
			& > li {
				& > a {
					.borderradius(0);
				}
				&.active {

				}
			}
		}
	}
}
