#maincontent {
	line-height: 30px;
}
#wrap {
	border-top: 4px solid @orange;
	margin-top: 0;
	&.wrap-header-fixed {
		background: url(/themes/rolliboard/resources/background.png);
		position: relative;
		z-index: 10000;
		margin-top: 334px;
	}
}


