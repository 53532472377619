.breadcrumb {
	font-size: 14px;
	margin-top: 4px;
	background: @grey;
	border: solid 2px @orange;
	li {
		color: @black;
		font-style: italic;
		.singlearrow() !important;
	}
	.active {
		color: @black;
		font-style: normal;
		font-weight: bold;
	}
	a {
		font-style: normal;
		font-weight: 600;
		color: @orange;
	}
}
