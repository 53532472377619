.borderradius(@radius) {
	-moz-border-radius: @radius;
	-webkit-box-shadow: @radius;
	border-radius: @radius;
}

.boxshadow (@setting) {
	-mox-box-shadow: @setting;
	-o-box-shadow: @setting;
	-webkit-box-shadow: @setting;
	box-shadow: @setting;
}

//Bootstrap Focus Fix
.nav > li > a:focus, .nav > li.active > a:focus {
	background: none;
	color: orange;
}

.nav-pills > li.active > a {
	background: none;
	color: @orange;
	&:hover {
		color: @orange;
	}
}

@navbar-border-radius: 0px;

@body-bg: @lightgrey;

@headings-font-family: "Roboto Slab", "Comic Sans MS";
@headings-font-weight: 700;
@font-family-base: "Open Sans", "Arial";
@font-size-base: 14px;

@nav-pills-border-radius: 0px;


* {
	box-sizing: border-box;
}
body {
	background: url('/themes/rolliboard/resources/background.png');
	@media screen and (min-width: @screen-md) {
		font-size: 18px;
	}
}

hr {
	background-color: @darkblue;
	border-color: @darkblue;
}

.less_artikelbild {
	margin-bottom: 8px;
	margin-right: 12px;
	& > .fc_image_thumb {
		.boxshadow(0 0 3px @black);
		max-width: 280px;
		display: inline-block;
		float: none;
		.borderradius(4px);
		border: 1px @lightgrey solid;
		overflow: hidden;
		background: @orange;
		&:hover {
			text-decoration: none;
		}
		& > img {
			background: none;
			border: none;
		}
		.alert-info {
			border: 0;
			text-decoration: none;
			margin-bottom: 0;
			background: none;
			padding: 4px;
			text-align: center;
			color: @black;
		}
	}
}

.less_einleitung {
	text-align: justify;
	font-size: normal;
	line-height: 1.6em;
}
.less_einleitung_ul {
	ul {
		margin-left: 270px;
	}
}

#maincontainer {
	padding-bottom: 80px;
	min-height: 375px;
}

.arrow-top(@inner, @border) {
	background: #88b7d5;
	border: 2px solid #c2e1f5;
	&:after, &:before {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(136, 183, 213, 0);
		border-bottom-color: @inner;
		.noise-2();
		border-width: 30px;
		margin-left: -30px;
	}
	&:before {
		border-color: rgba(194, 225, 245, 0);
		border-bottom-color: @border;
		border-width: 33px;
		margin-left: -33px;
	}
}
