h2.page-itemheader {
	font-size: 20px;
	color: black;
	& > a {
		color: @orange;
		&:visited, &:focus, &:hover {
			color: @orange;
		}
	}
}
