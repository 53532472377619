@import "color.less";

//font-awesome font-icon lib
@import "../css/font-awesome.min.css";
@import "variables.less";

@import "bootstrap/bootstrap.less";

@import "partials/noise";
@import "partials/arrow";
@import "partials/gradients";
@import "partials/featuredImage";

@import "components/field-artikelbild";
@import "components/field-einleitung";
@import "components/field-text";
@import "components/header/pagename-and-slogan";
@import "components/footer";
@import "components/footer-menue";
@import "components/header/contact";
@import "components/header";
@import "components/page-header";
@import "components/page-itemheader";
@import "components/readmore";
@import "components/blogPosts/blogPosts";
@import "components/blogPost/blogPost";

// Temporary navbar
@import "navbar_global";

@import "global.less";
@import "navbar.less";

@import "sidebar.less";
@import "article.less";
@import "breadcrumb.less";
@import "navtop.less";

@import "category.less";
@import "pagination";
@import "sm-own";

@import "flexi_migrate";


@import "sections/categorylist";

@import "maincontent";
