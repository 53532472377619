.singlearrow() {
	color: black;
	font-weight: bold;
	&:before {
		display: inline-block;
		/* background: url(/templates/phpkitOld/images/sprite-alt.png) -303px 0px no-repeat; */
		background: url(/themes/rolliboard/resources/sprite.png) -217px -4px no-repeat;
		width: 18px;
		height: 12px;
		margin-right: 0px;
		vertical-align: middle;
		content: "";
	}
}

.singlearrow-hover() {
	color: black;
	font-weight: bold;
	&:before {
		display: inline-block;
		/* background: url(/templates/phpkitOld/images/sprite-alt.png) -303px 0px no-repeat; */
		background: url(/themes/rolliboard/resources/sprite.png) -217px -4px no-repeat;
		width: 18px;
		height: 12px;
		margin-right: 0px;
		vertical-align: middle;
		transition-property: margin-right;
		transition-duration: 0.5s;
		content: "";
	}
	&:hover:before {
		margin-right: 10px;
		transition-property: margin-right;
		transition-duration: 0.5s;
		color: black;
	}
}

.doublearrow() {
	color: black;
	font-weight: bold;
	&:before {
		display: inline-block;
		/* background: url(/templates/phpkitOld/images/sprite-alt.png) -303px 0px no-repeat; */
		background: url(/themes/rolliboard/resources/sprite.png) -217px -4px no-repeat;
		width: 18px;
		height: 12px;
		margin-right: 0px;
		vertical-align: middle;
		content: "";
	}
	&:after {
		display: inline-block;
		/* background: url(/templates/phpkitOld/images/sprite-alt.png) -329px 0px no-repeat; */
		background: url(/themes/rolliboard/resources/sprite.png) -235px -4px no-repeat;
		width: 18px;
		height: 12px;
		margin-right: 0px;
		vertical-align: middle;
		content: "";
	}
}

.doublearrow-hover() {
	color: black;
	font-weight: bold;
	&:before {
		display: inline-block;
		/* background: url(/templates/phpkitOld/images/sprite-alt.png) -303px 0px no-repeat; */
		background: url(/themes/rolliboard/resources/sprite.png) -217px -4px no-repeat;
		width: 18px;
		height: 12px;
		margin-right: 0px;
		vertical-align: middle;
		transition-property: margin-right;
		transition-duration: 0.5s;
		content: "";
	}
	&:after {
		display: inline-block;
		/* background: url(/templates/phpkitOld/images/sprite-alt.png) -329px 0px no-repeat; */
		background: url(/themes/rolliboard/resources/sprite.png) -235px -4px no-repeat;
		width: 18px;
		height: 12px;
		margin-left: 0px;
		vertical-align: middle;
		transition-property: margin-left;
		transition-duration: 0.5s;
		content: "";
	}
	&:hover:before {
		margin-right: 10px;
		transition-property: margin-right;
		transition-duration: 1s;
		color: black;
	}
	&:hover:after {
		margin-left: 10px;
		transition-property: margin-left;
		transition-duration: 0.5s;
		color: black;
	}
}
